
import Portfolio from  './components/Portfolio'

function App() {
  return (
    <div className="App">
      <Portfolio/>
    </div>
  );
}

export default App;
